import {
  Add_default,
  CloseLg_default,
  DefaultPetProfile_default,
  IconAdd,
  IconAddCircleOutline_default,
  IconAddOutlineBlue_default,
  IconBigCalendar_default,
  IconCalendar_default,
  IconCat,
  IconCatDog_default,
  IconCheckbox,
  IconCheckboxBase,
  IconCheckboxInvalid,
  IconCloseOutlined_default,
  IconClose_default,
  IconDefaultProfilePic_default,
  IconDismissButton_default,
  IconDog,
  IconDownArrow_default,
  IconEditPhoto_default,
  IconEditSquareOutine_default,
  IconExpandDown_default,
  IconFoodFinder_default,
  IconHome,
  IconInfo_default,
  IconLeading_default,
  IconNext_default,
  IconPrev_default,
  IconSetting,
  IconWarning_default,
  PersonOutline_default,
  ToastCross_default,
  ToastSuccess_default,
  __objRest,
  __spreadProps,
  __spreadValues
} from "./chunk-BXSMLY7G.mjs";

// src/components/Button/Button.tsx
import { forwardRef } from "react";
import { jsx, jsxs } from "react/jsx-runtime";
var typeClasses = {
  primary: `bg-brand-primary text-white px-4
  ease-out hover:transition duration-300 active:duration-0
  hover:bg-brand-color-library-blue-600
  active:bg-brand-color-library-blue-800
  focus:outline focus:outline-offset-2 focus:outline-1 focus:outline-brand-primary`,
  blueOutlined: `bg-white border border-brand-primary px-4 text-brand-primary
  ease-out hover:transition duration-300 active:duration-0
  hover:bg-brand-color-library-blue-100
  active:bg-brand-color-library-blue-200
  focus:outline focus:outline-offset-2 focus:outline-1 focus:outline-brand-primary`,
  grayOutlined: `bg-white border border-brand-color-library-gray-800 text-brand-color-library-gray-800 px-4
  ease-out hover:transition duration-300 active:duration-0
  hover:bg-brand-color-library-gray-100
  active:bg-brand-color-library-gray-200
  focus:outline focus:outline-offset-2 focus:outline-1 focus:outline-brand-primary`,
  dangerOutlined: `bg-white border border-brand-color-library-red-800 text-brand-color-library-red-800 px-4
  ease-out hover:transition duration-300 active:duration-0
  hover:bg-brand-color-library-red-100  
  active:bg-brand-color-library-red-300 
  focus:outline focus:outline-offset-2 focus:outline-1 focus:outline-brand-primary`,
  blueText: `bg-white text-brand-primary px-4
  ease-out hover:transition duration-300 active:duration-0
  hover:bg-brand-color-library-blue-100
  active:bg-brand-color-library-blue-200
  focus:outline focus:outline-offset-2 focus:outline-1 focus:outline-brand-primary`,
  dangerText: `bg-white text-brand-error px-4
  ease-out hover:transition duration-300 active:duration-0
  hover:bg-brand-color-library-red-100  
  active:bg-brand-color-library-red-300 
  focus:outline focus:outline-offset-2 focus:outline-1 focus:outline-brand-primary`,
  icon: `p-[5px] rounded
  ease-out hover:transition duration-300 active:duration-0
  hover:bg-brand-color-library-gray-100 
  active:bg-brand-color-library-gray-200`
};
var sizeClasses = {
  sm: "py-2 label2 rounded",
  lg: "py-2 label1 w-full rounded lg:w-auto"
};
var iconColourMap = {
  primary: "fill-white",
  blueOutlined: "fill-brand-primary",
  grayOutlined: "fill-brand-color-library-gray-800",
  dangerOutlined: "fill-brand-color-library-red-800",
  blueText: "fill-brand-primary",
  dangerText: "fill-brand-error"
};
var iconSizeClass = {
  sm: "w-[18px] h-[18px]",
  lg: "w-6 h-6"
};
var Button = forwardRef((_a, ref) => {
  var _b = _a, {
    variant = "primary",
    children = "Submit",
    size = "sm",
    classes = "",
    isLoading,
    prefixIcon,
    suffixIcon,
    icon
  } = _b, rest = __objRest(_b, [
    "variant",
    "children",
    "size",
    "classes",
    "isLoading",
    "prefixIcon",
    "suffixIcon",
    "icon"
  ]);
  return /* @__PURE__ */ jsx(
    "button",
    __spreadProps(__spreadValues({}, rest), {
      ref,
      className: `disabled:opacity-60 ${typeClasses[variant]} ${variant !== "icon" ? sizeClasses[size] : ""}
       ${classes}`,
      children: isLoading ? /* @__PURE__ */ jsx("div", { className: "w-24", children: /* @__PURE__ */ jsx(
        "div",
        {
          className: "inline-block h-4 w-4\n            animate-spin \n            rounded-full  \n            border-t-2 \n            border-t-gray-500"
        }
      ) }) : variant !== "icon" ? /* @__PURE__ */ jsxs("div", { className: "flex items-center justify-center gap-1", children: [
        prefixIcon && /* @__PURE__ */ jsx(
          "div",
          {
            className: `flex items-center justify-center
              ${iconSizeClass[size]}
              ${iconColourMap[variant]}`,
            children: prefixIcon
          }
        ),
        children,
        suffixIcon && /* @__PURE__ */ jsx(
          "div",
          {
            className: `flex items-center justify-center
              ${iconSizeClass[size]}
              ${iconColourMap[variant]} `,
            children: suffixIcon
          }
        )
      ] }) : /* @__PURE__ */ jsx("div", { className: "flex items-center justify-center gap-2", children: icon })
    })
  );
});
Button.displayName = "Button";

// src/components/Button/IconButton.tsx
import { jsx as jsx2 } from "react/jsx-runtime";
var IconButton = (props) => {
  return /* @__PURE__ */ jsx2(
    Button,
    __spreadProps(__spreadValues({}, props), {
      classes: `${props.classes}`,
      variant: "icon",
      children: ""
    })
  );
};

// src/components/ArticleCard/ArticleCard.tsx
import { jsx as jsx3, jsxs as jsxs2 } from "react/jsx-runtime";
var ArticleCard = (_a) => {
  var _b = _a, {
    article,
    classes
  } = _b, htmlAttrProps = __objRest(_b, [
    "article",
    "classes"
  ]);
  return /* @__PURE__ */ jsx3(
    "div",
    __spreadProps(__spreadValues({
      role: "listitem",
      "aria-label": article.title,
      "data-testid": "petTile",
      className: `mb-2.5 flex rounded-lg ${classes} min-w-[280px] overflow-hidden border-[1px] 
       border-brand-color-library-gray-200 !no-underline shadow-dp2`
    }, htmlAttrProps), {
      children: /* @__PURE__ */ jsxs2(
        "a",
        {
          href: article.url,
          target: "_blank",
          "data-testid": "article",
          title: article.title,
          className: "w-full !no-underline",
          rel: "noreferrer",
          children: [
            !article.image ? /* @__PURE__ */ jsx3("div", { className: ``, "data-testid": "defaultImg", children: /* @__PURE__ */ jsx3(DefaultPetProfile_default, { className: "h-28 w-full md:h-44" }) }) : /* @__PURE__ */ jsx3(
              "img",
              {
                src: article.image,
                alt: article.title + " image",
                className: "h-24 w-full object-cover lg:h-40",
                "data-testid": "articleImg"
              }
            ),
            /* @__PURE__ */ jsx3("h3", { className: "body3bold lg:label2 w-full p-4 lg:p-6", children: article.title })
          ]
        }
      )
    })
  );
};
ArticleCard.displayName = "ArticleCard";

// src/icons/ImageIcon.tsx
import { jsx as jsx4 } from "react/jsx-runtime";
function ImageIcon(props) {
  return /* @__PURE__ */ jsx4(
    "svg",
    __spreadProps(__spreadValues({
      width: "30",
      height: "30",
      viewBox: "0 0 30 30",
      xmlns: "http://www.w3.org/2000/svg",
      "aria-hidden": true
    }, props), {
      children: /* @__PURE__ */ jsx4("path", { d: "M30 26.6667V3.33333C30 1.5 28.5 0 26.6667 0H3.33333C1.5 0 0 1.5 0 3.33333V26.6667C0 28.5 1.5 30 3.33333 30H26.6667C28.5 30 30 28.5 30 26.6667ZM9.16667 17.5L13.3333 22.5167L19.1667 15L26.6667 25H3.33333L9.16667 17.5Z" })
    })
  );
}

// src/components/ArticleCard/ArticleCardSkeleton.tsx
import { jsx as jsx5, jsxs as jsxs3 } from "react/jsx-runtime";
function ArticleCardSkeleton() {
  return /* @__PURE__ */ jsxs3("div", { className: "min-w-[280px] overflow-hidden rounded-lg border border-brand-color-library-gray-200 shadow-dp2", children: [
    /* @__PURE__ */ jsx5("div", { className: "flex h-28 w-full items-center justify-center bg-brand-color-library-gray-100 md:h-44", children: /* @__PURE__ */ jsx5(ImageIcon, {}) }),
    /* @__PURE__ */ jsx5("div", { className: "flex items-center px-4 py-5 md:px-6 md:py-8 ", children: /* @__PURE__ */ jsx5("div", { className: "animate-loading-gradient h-3 w-2/3 rounded-full bg-gradient-to-r from-gray-400 via-gray-200 to-gray-400 bg-[length:200%_auto]" }) })
  ] });
}

// src/components/ProductCard/ProductCardFilter.tsx
import { jsx as jsx6, jsxs as jsxs4 } from "react/jsx-runtime";
var ProductCardImageFilter = () => {
  return /* @__PURE__ */ jsx6(
    "div",
    {
      "aria-label": "filter-image",
      role: "img",
      className: `h-28 w-28 rounded bg-[#F2F2F2] 
    bg-gradient-to-r from-[rgba(0,0,0,0.00)_0%] to-[rgba(0,0,0,0.10)_100%] lg:h-60
    lg:w-full`
    }
  );
};
var ProductCardTextFilter = () => {
  return /* @__PURE__ */ jsxs4(
    "div",
    {
      "aria-label": "filter-textlines",
      role: "article",
      className: "flex flex-col items-start gap-2 self-stretch lg:gap-3",
      children: [
        /* @__PURE__ */ jsx6(
          "div",
          {
            className: `h-3.5 w-full rounded
      bg-[#F2F2F2] bg-gradient-to-r
      from-[rgba(0,0,0,0.00)_0%] to-[rgba(0,0,0,0.10)_100%] lg:h-[18px]`
          }
        ),
        /* @__PURE__ */ jsx6(
          "div",
          {
            className: `h-3.5 w-full rounded bg-[#F2F2F2]
      bg-gradient-to-r  from-[rgba(0,0,0,0.00)_0%]
      to-[rgba(0,0,0,0.10)_100%] lg:hidden lg:h-[18px]
      `
          }
        ),
        /* @__PURE__ */ jsx6(
          "div",
          {
            className: `h-3.5 w-[121px] rounded bg-[#F2F2F2]
      bg-gradient-to-r from-[rgba(0,0,0,0.00)_0%]
      to-[rgba(0,0,0,0.10)_100%] lg:h-[18px] lg:w-[230px]`
          }
        )
      ]
    }
  );
};

// src/components/ProductCard/ProductCard.tsx
import { jsx as jsx7, jsxs as jsxs5 } from "react/jsx-runtime";
import { createElement } from "react";
var ProductCard = (_a) => {
  var _b = _a, {
    name = "",
    id = "",
    classes = "",
    primaryProductImageUrl = "",
    select = "Select",
    isLoading = false,
    buttonCallback
  } = _b, rest = __objRest(_b, [
    "name",
    "id",
    "classes",
    "primaryProductImageUrl",
    "select",
    "isLoading",
    "buttonCallback"
  ]);
  return /* @__PURE__ */ createElement(
    "div",
    __spreadProps(__spreadValues({
      "aria-label": "productCard",
      "data-testid": "productCard",
      className: "flex w-[320px] flex-row items-center justify-between gap-2 rounded py-4 px-5 lg:w-[370px] lg:flex-col lg:gap-6 lg:px-4 "
    }, rest), {
      id,
      key: id
    }),
    isLoading ? /* @__PURE__ */ jsx7(ProductCardImageFilter, {}) : /* @__PURE__ */ jsx7("div", { className: "w-28 lg:w-full", children: /* @__PURE__ */ jsx7(
      "img",
      {
        className: "mx-auto w-full lg:h-40 lg:w-auto",
        src: primaryProductImageUrl,
        alt: name,
        "aria-label": "product-image"
      }
    ) }),
    /* @__PURE__ */ jsxs5("div", { className: "flex w-full flex-[1_0_0%] flex-col gap-4 lg:gap-6", children: [
      isLoading ? /* @__PURE__ */ jsx7(ProductCardTextFilter, {}) : /* @__PURE__ */ jsx7("div", { className: "body3 lg:label1", children: name }),
      /* @__PURE__ */ jsx7(
        Button,
        {
          variant: "blueOutlined",
          "aria-label": "select product",
          type: "button",
          classes: "w-full lg:label1",
          onClick: () => {
            !isLoading && buttonCallback(id);
          },
          size: "sm",
          children: select
        }
      )
    ] })
  );
};
ProductCard.displayName = "ProductCard";

// src/components/Loader/Loader.tsx
import { classnames } from "utils";
import { jsx as jsx8, jsxs as jsxs6 } from "react/jsx-runtime";
var sizeMap = {
  small: "h-4 w-4",
  medium: "h-8 w-8",
  large: "h-12 w-12"
};
var Loader = ({
  size = "medium",
  className,
  svgClassname
}) => {
  return /* @__PURE__ */ jsx8("div", { className: classnames("text-center", className), children: /* @__PURE__ */ jsxs6("div", { role: "status", children: [
    /* @__PURE__ */ jsxs6(
      "svg",
      {
        className: classnames(
          "mr-2 inline animate-spin fill-blue-600 text-gray-200",
          sizeMap[size],
          svgClassname
        ),
        viewBox: "0 0 100 101",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
        children: [
          /* @__PURE__ */ jsx8(
            "path",
            {
              d: "M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z",
              fill: "currentColor"
            }
          ),
          /* @__PURE__ */ jsx8(
            "path",
            {
              d: "M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z",
              fill: "currentFill"
            }
          )
        ]
      }
    ),
    /* @__PURE__ */ jsx8("span", { className: "sr-only", children: "Loading..." })
  ] }) });
};
Loader.displayName = "Loader";

// src/components/Sidebar/SidebarItems.tsx
import { jsxs as jsxs7 } from "react/jsx-runtime";
var SidebarItems = ({ item, render }) => {
  var _a;
  return /* @__PURE__ */ jsxs7("div", { className: "w-full", "data-testid": "sidebar_items", children: [
    render(item, false, 0),
    item.subcategory && ((_a = item.subcategory) == null ? void 0 : _a.map((subcat, index) => {
      return render(subcat, true, index);
    }))
  ] });
};
var SidebarItems_default = SidebarItems;

// src/components/Sidebar/Sidebar.tsx
import { jsx as jsx9 } from "react/jsx-runtime";
var Sidebar = ({ items, render }) => {
  return /* @__PURE__ */ jsx9("div", { className: "hidden lg:flex h-fit w-[256px] flex-col items-start inline-block rounded-l-lg border-r-[1px] border-brand-color-library-gray-200", children: items.map((item, index) => {
    return /* @__PURE__ */ jsx9(SidebarItems_default, { item, render }, index);
  }) });
};
Sidebar.displayName = "Sidebar";

// src/components/AppProtectedLayout/AppProtectedLayout.tsx
import { jsx as jsx10, jsxs as jsxs8 } from "react/jsx-runtime";
function AppProtectedLayout({
  sideMenu,
  content,
  upperContent,
  lowerContent
}) {
  return /* @__PURE__ */ jsxs8("div", { className: "grid_v2 tw-container mt-0 w-full justify-center px-0 lg:grid-cols-[256px_552px] gap-y-8 lg:gap-x-20", children: [
    /* @__PURE__ */ jsx10("div", { className: "col-span-2 hidden lg:block", children: upperContent }),
    sideMenu,
    /* @__PURE__ */ jsx10("main", { className: "w-full lg:w-[552px]", children: content }),
    lowerContent && /* @__PURE__ */ jsx10("div", { className: "col-span-2 lg:pt-2", children: lowerContent })
  ] });
}
AppProtectedLayout.displayName = "AppProtectedLayout";

// src/components/Tabs/Tabs.tsx
import { useCallback, useState } from "react";

// src/components/Tabs/TabTitle.tsx
import { classnames as classnames2 } from "utils";
import { jsx as jsx11 } from "react/jsx-runtime";
var TabTitle = ({
  title,
  activeItemIdx,
  setActive,
  index,
  disabled
}) => {
  return /* @__PURE__ */ jsx11(
    "button",
    {
      id: `tab-${index}`,
      type: "button",
      role: "tab",
      "aria-selected": activeItemIdx === index,
      "aria-controls": `tabpanel-${index}`,
      onClick: () => setActive(index),
      className: classnames2(
        activeItemIdx === index ? "bold border-b-[3px] border-brand-primary text-brand-primary focus:outline-none" : "hover:border-brand-color-library-blue-100 focus:rounded",
        "body3 lg:body1",
        "mt-3 rounded-t py-2 px-3 text-center hover:cursor-pointer hover:bg-brand-color-library-blue-100 focus:z-[1]",
        "focus:outline focus:outline-1 focus:outline-brand-color-library-blue-500 lg:py-3 lg:px-8"
      ),
      disabled,
      children: title
    }
  );
};

// src/components/Tabs/Tabs.tsx
import { jsx as jsx12, jsxs as jsxs9 } from "react/jsx-runtime";
var Tabs = ({
  children,
  classes,
  activeIndex,
  onActiveIndexChange
}) => {
  const [_activeItemIdx, setActiveItemIdx] = useState(activeIndex || 0);
  const activeItemIdx = activeIndex !== void 0 ? activeIndex : _activeItemIdx;
  const handleActiveItemIndexChange = useCallback(
    function handleActiveItemIndexChange2(index) {
      const updateFn = onActiveIndexChange || setActiveItemIdx;
      updateFn(index);
    },
    [onActiveIndexChange]
  );
  return /* @__PURE__ */ jsxs9("div", { className: `${classes} w-full`, children: [
    /* @__PURE__ */ jsx12(
      "div",
      {
        role: "tablist",
        className: "no-scrollbar flex items-center overflow-x-auto border-b-[1px] pl-1",
        children: children.map((item, index) => {
          return /* @__PURE__ */ jsx12(
            TabTitle,
            {
              title: item.props.title,
              disabled: item.props.disabled,
              setActive: handleActiveItemIndexChange,
              activeItemIdx,
              index
            },
            index
          );
        })
      }
    ),
    children.map((item, idx) => /* @__PURE__ */ jsx12(
      "div",
      {
        id: `tabpanel-${idx}`,
        role: "tabpanel",
        tabIndex: 0,
        "aria-labelledby": `tab-${idx}`,
        className: `${activeItemIdx === idx ? "block" : "hidden"}`,
        children: item.props.children
      },
      idx
    ))
  ] });
};

// src/components/Tabs/Tab.tsx
import { jsx as jsx13 } from "react/jsx-runtime";
var Tab = ({
  children,
  classes
}) => {
  return /* @__PURE__ */ jsx13("div", { className: classes, children });
};

// src/components/Chip/Chip.tsx
import { jsx as jsx14, jsxs as jsxs10 } from "react/jsx-runtime";
var Chip = (_a) => {
  var _b = _a, { children, classes } = _b, props = __objRest(_b, ["children", "classes"]);
  return /* @__PURE__ */ jsxs10(
    "button",
    __spreadProps(__spreadValues({
      className: `${classes ? classes : ""}
      flex h-[38px] w-fit flex-shrink-0 items-center justify-evenly gap-2 
      rounded-[40px] bg-brand-color-library-blue-200 py-2 pl-3 pr-2 
      hover:cursor-pointer hover:bg-brand-color-library-blue-300 focus:outline 
      focus:outline-1 focus:outline-offset-1 focus:outline-brand-color-library-blue-500 
      lg:h-[42px] lg:pl-4 lg:pr-3`
    }, props), {
      children: [
        /* @__PURE__ */ jsx14("span", { className: "body3 text-brand-color-library-gray-900 ", children }),
        /* @__PURE__ */ jsx14("span", { className: "flex h-4 w-4 items-center justify-center fill-brand-color-library-gray-800 hover:cursor-pointer", children: IconClose_default })
      ]
    })
  );
};
Chip.displayName = "Chip";

// src/components/Accordion/Accordion.tsx
import { useState as useState3 } from "react";

// src/components/Accordion/AccordionItem.tsx
import { useEffect, useRef, useState as useState2 } from "react";
import { jsx as jsx15, jsxs as jsxs11 } from "react/jsx-runtime";
var AccordionItem = ({
  data,
  isOpen,
  idx,
  btnClick
}) => {
  const contentRef = useRef(null);
  const [height, setHeight] = useState2(0);
  useEffect(() => {
    if (isOpen) {
      const contentEl = contentRef.current;
      setHeight(contentEl.scrollHeight);
    } else {
      setHeight(0);
    }
  }, [isOpen]);
  return /* @__PURE__ */ jsxs11("li", { children: [
    /* @__PURE__ */ jsxs11(
      "button",
      {
        type: "button",
        "aria-expanded": isOpen,
        id: `accordion${idx}id`,
        "aria-controls": `sect${idx}`,
        "aria-label": "accordion-title",
        tabIndex: 0,
        className: `offset-outline flex w-full items-center justify-between
        rounded-[5px] py-3 hover:cursor-pointer `,
        onClick: btnClick,
        children: [
          /* @__PURE__ */ jsx15("div", { className: "label2 lg:label1 text-[#44464B]", children: data.title }),
          /* @__PURE__ */ jsx15(
            "div",
            {
              className: `h-6 w-6 fill-[#0054A4] transition-all duration-200 ${isOpen ? "rotate-180" : ""}`,
              children: IconDownArrow_default
            }
          )
        ]
      }
    ),
    /* @__PURE__ */ jsx15("div", { className: "h-[1px] w-full bg-[#C6C7CC]" }),
    /* @__PURE__ */ jsx15(
      "div",
      {
        "aria-labelledby": `accordion${idx}id`,
        role: "region",
        id: `sect${idx}`,
        className: `${isOpen ? "opacity-1	visible" : "invisible opacity-0"} overflow-hidden transition-all duration-200 ease-in-out`,
        ref: contentRef,
        style: { height },
        children: data.content
      }
    ),
    /* @__PURE__ */ jsx15(
      "div",
      {
        className: `${isOpen ? "block" : "hidden"} h-[1px] w-full bg-[#C6C7CC]`
      }
    )
  ] });
};
var AccordionItem_default = AccordionItem;

// src/components/Accordion/Accordion.tsx
import { jsx as jsx16 } from "react/jsx-runtime";
var Accordion = ({
  items,
  parentClasses
}) => {
  const [currentIdx, setCurrentIdx] = useState3(-1);
  const btnClick = (idx) => {
    setCurrentIdx((currentValue) => currentValue !== idx ? idx : -1);
  };
  return /* @__PURE__ */ jsx16("ul", { className: parentClasses, children: items.map((item, idx) => /* @__PURE__ */ jsx16(
    AccordionItem_default,
    {
      data: item,
      idx,
      isOpen: idx === currentIdx,
      btnClick: () => btnClick(idx)
    },
    idx
  )) });
};
Accordion.displayName = "Accordion";

// src/components/DefaultImage/PetNameImage.tsx
import { jsx as jsx17 } from "react/jsx-runtime";
var PetNameDefaultImage = (petName) => {
  return /* @__PURE__ */ jsx17(
    "div",
    {
      role: "img",
      "aria-label": "default-image",
      className: "label2 rounded-full bg-[#E6E6E6] px-[14px] py-[16px] text-center text-[#34363B]",
      children: petName.substring(0, 2)
    }
  );
};
PetNameDefaultImage.displayName = "PetNameDefaultImage";

// src/components/FormControls/FormControl.tsx
import { Field } from "react-final-form";

// src/components/FormControls/InputTypes/RadioInput.tsx
import { classnames as classnames3 } from "utils";
import { jsx as jsx18, jsxs as jsxs12 } from "react/jsx-runtime";
var RadioInput = ({
  input,
  children,
  isUncheckable,
  label,
  variant,
  mode,
  invalid,
  isRequired
}) => {
  const isChecked = input.checked;
  const isBlockVariant = variant === "block";
  const handleRadioClick = () => {
    if (isUncheckable && isChecked) {
      input.onChange(null);
    } else {
      input.onChange(input.value);
    }
  };
  return /* @__PURE__ */ jsxs12(
    "label",
    {
      onClick: handleRadioClick,
      className: classnames3(
        "group inline-flex cursor-pointer flex-col items-start  peer-disabled:opacity-40",
        isBlockVariant && [
          "w-full rounded border py-4 px-4",
          invalid && "border-brand-error",
          !invalid && !isChecked && !mode && "border-brand-accent-gray-light",
          !invalid && isChecked && !mode && "border-brand-primary bg-blue-100",
          !invalid && mode && "cursor-default",
          !invalid && mode === "correct" && "border-green-800 bg-green-100",
          !invalid && mode === "incorrect" && "border-red-800 bg-red-100"
        ]
      ),
      children: [
        /* @__PURE__ */ jsxs12("div", { className: "flex items-center", children: [
          /* @__PURE__ */ jsx18(
            "input",
            __spreadProps(__spreadValues({}, input), {
              type: "radio",
              className: classnames3(
                input.className,
                "peer mr-2 h-5 w-5 appearance-none rounded-full border",
                "bg-white bg-contain bg-center bg-no-repeat align-top transition duration-200",
                "checked:border-8 checked:border-brand-primary focus:outline focus:outline-1 focus:outline-offset-1 focus:outline-[#0054A4]",
                "disabled:opacity-40 disabled:focus:outline-none",
                "group-hover:bg-[#EEF5FF] group-hover:outline group-hover:outline-2 group-hover:outline-[#EEF5FF]",
                mode ? "cursor-default" : "cursor-pointer",
                isChecked ? "border-8 border-brand-primary" : invalid ? "border-2 border-brand-error" : "border-brand-color-library-gray-300"
              ),
              tabIndex: 0,
              onClick: handleRadioClick
            })
          ),
          label && /* @__PURE__ */ jsxs12("span", { className: "body2 peer-disabled:opacity-40", children: [
            label,
            isRequired && /* @__PURE__ */ jsx18("span", { className: "text-brand-color-library-red-800", children: " *" })
          ] })
        ] }),
        isBlockVariant && children
      ]
    }
  );
};
var RadioInput_default = RadioInput;

// src/components/FormControls/InputTypes/CheckboxInput.tsx
import { classnames as classnames4 } from "utils";
import { jsx as jsx19, jsxs as jsxs13 } from "react/jsx-runtime";
var CheckboxInput = ({
  input,
  label,
  invalid,
  isRequired
}) => {
  const checkboxIconStyles = `pointer-events-none h-5 w-5 rounded 
                              peer-hover:bg-brand-color-library-blue-100 
                              peer-focus:outline peer-focus:outline-1 peer-focus:outline-offset-1 
                              peer-focus:outline-brand-color-library-blue-500`;
  return /* @__PURE__ */ jsxs13(
    "label",
    {
      className: classnames4(
        "inline-flex cursor-pointer items-center p-1",
        invalid && "rounded border border-brand-error"
      ),
      children: [
        /* @__PURE__ */ jsx19("input", __spreadProps(__spreadValues({}, input), { type: "checkbox", className: "peer appearance-none" })),
        /* @__PURE__ */ jsx19(
          "div",
          {
            className: [checkboxIconStyles, "block peer-checked:hidden"].join(" "),
            "aria-hidden": true,
            children: invalid ? IconCheckboxInvalid : IconCheckboxBase
          }
        ),
        /* @__PURE__ */ jsx19(
          "div",
          {
            className: [checkboxIconStyles, "hidden peer-checked:block"].join(" "),
            "aria-hidden": true,
            children: invalid ? IconCheckboxInvalid : IconCheckbox
          }
        ),
        label && /* @__PURE__ */ jsxs13("span", { className: "body3 lg:body2 pl-2 font-normal text-black", children: [
          label,
          isRequired && /* @__PURE__ */ jsx19("span", { className: "text-brand-color-library-red-800", children: " *" })
        ] })
      ]
    }
  );
};
var CheckboxInput_default = CheckboxInput;

// src/components/FormControls/InputTypes/InputField.tsx
import React5 from "react";
import { jsx as jsx20, jsxs as jsxs14 } from "react/jsx-runtime";
var InputField = ({
  input,
  invalid,
  label,
  isRequired
}) => {
  const defaultId = React5.useId();
  const id = input.id || defaultId;
  return /* @__PURE__ */ jsxs14("div", { children: [
    label && /* @__PURE__ */ jsxs14("label", { className: "label2 mb-2 sm:label1", htmlFor: id, children: [
      label,
      isRequired && /* @__PURE__ */ jsx20("span", { className: "text-brand-color-library-red-800", children: " *" })
    ] }),
    /* @__PURE__ */ jsx20(
      "input",
      __spreadProps(__spreadValues({}, input), {
        id: "InputField",
        className: `block w-full grow appearance-none rounded-md border bg-transparent py-1 px-4 
          text-lg text-brand-color-library-gray-800 hover:border-brand-color-library-gray-500
          placeholder-brand-color-library-gray-500 border-brand-color-library-gray-300
          focus:outline focus:outline-1 focus:outline-offset-2 focus:outline-brand-primary 
           disabled:opacity-40 ${invalid ? "!border-brand-error focus:outline-0" : ""} mt-2`
      })
    )
  ] });
};
var InputField_default = InputField;

// src/components/FormControls/InputTypes/TextAreaInput.tsx
import { useState as useState4 } from "react";
import { jsx as jsx21, jsxs as jsxs15 } from "react/jsx-runtime";
var TextAreaInput = ({
  input,
  invalid,
  label,
  isRequired
}) => {
  const [lineCount, setLineCount] = useState4(1);
  const maxLines = 5;
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && lineCount >= maxLines) {
      event.preventDefault();
    }
  };
  const handleChange = (event) => {
    const lines = event.target.value.split("\n").length;
    if (lines <= maxLines) {
      setLineCount(lines);
    }
    input.onChange(event);
  };
  return /* @__PURE__ */ jsxs15("div", { className: "grid_hphv2", children: [
    label && /* @__PURE__ */ jsxs15("label", { className: "label2", htmlFor: "TextareaField", children: [
      label,
      isRequired && /* @__PURE__ */ jsx21("span", { className: "text-brand-color-library-red-800", children: " *" })
    ] }),
    /* @__PURE__ */ jsx21(
      "textarea",
      __spreadProps(__spreadValues({}, input), {
        id: "TextareaField",
        className: `block grow appearance-none rounded-md
          border border-brand-color-library-gray-300 bg-transparent py-1 px-4 text-lg text-brand-color-library-gray-800 placeholder-brand-color-library-gray-500 hover:border-brand-color-library-gray-500 focus:outline focus:outline-1 focus:outline-offset-2 focus:outline-brand-primary  disabled:opacity-40 ${invalid ? "border-brand-error focus:outline-0" : ""}`,
        rows: lineCount,
        onKeyDown: handleKeyDown,
        onChange: handleChange,
        style: { overflow: "hidden", resize: "none" }
      })
    )
  ] });
};
var TextAreaInput_default = TextAreaInput;

// src/components/FormControls/GetFormControlType.tsx
import { Fragment, jsx as jsx22 } from "react/jsx-runtime";
var getFormControlType = (type, input, children, isUncheckable, label, invalid, variant, mode, isRequired) => {
  switch (type) {
    case "input":
      if (input.type == "radio") {
        return /* @__PURE__ */ jsx22(RadioInput_default, { input, children, isUncheckable, label, variant, mode, invalid, isRequired });
      } else if (input.type == "checkbox") {
        return /* @__PURE__ */ jsx22(CheckboxInput_default, { input, label, invalid, isRequired });
      } else if (input.type == "textarea") {
        return /* @__PURE__ */ jsx22(TextAreaInput_default, { input, invalid, label, isRequired });
      }
      return /* @__PURE__ */ jsx22(InputField_default, { input, invalid, label, isRequired });
    default:
      return /* @__PURE__ */ jsx22(Fragment, { children });
  }
};
var GetFormControlType_default = getFormControlType;

// src/components/FormControls/FormControl.tsx
import { Fragment as Fragment2, jsx as jsx23, jsxs as jsxs16 } from "react/jsx-runtime";
var FormControl = (_a) => {
  var _b = _a, {
    label,
    children,
    placeholder,
    classes = "",
    subtext = "",
    disabled = false,
    type = "text",
    onBlurCapture,
    onChange,
    max = "",
    fieldProps,
    flexDirection = "col",
    optionalText = "",
    isRequired = false,
    errorShort = false,
    value,
    isUncheckable = false,
    variant,
    mode
  } = _b, rest = __objRest(_b, [
    "label",
    "children",
    "placeholder",
    "classes",
    "subtext",
    "disabled",
    "type",
    "onBlurCapture",
    "onChange",
    "max",
    "fieldProps",
    "flexDirection",
    "optionalText",
    "isRequired",
    "errorShort",
    "value",
    "isUncheckable",
    "variant",
    "mode"
  ]);
  return /* @__PURE__ */ jsx23("div", { children: /* @__PURE__ */ jsx23(Field, __spreadProps(__spreadValues({}, fieldProps), { type, children: ({ input, meta }) => {
    const inputProps = onChange ? __spreadValues(__spreadProps(__spreadValues({}, input), {
      placeholder,
      disabled,
      max,
      id: input.name,
      onBlurCapture,
      onChange
    }), rest) : __spreadValues(__spreadProps(__spreadValues({}, input), {
      placeholder,
      disabled,
      max,
      id: input.name,
      onBlurCapture
    }), rest);
    return /* @__PURE__ */ jsxs16("div", { "data-testid": "textbox", children: [
      GetFormControlType_default(
        fieldProps.component,
        inputProps,
        children,
        isUncheckable,
        label,
        meta.error && meta.touched,
        variant,
        mode,
        isRequired
      ),
      optionalText && /* @__PURE__ */ jsxs16(Fragment2, { children: [
        /* @__PURE__ */ jsx23("div", { className: "h-2" }),
        /* @__PURE__ */ jsx23("span", { className: "text-sm text-brand-color-library-gray-900", children: optionalText })
      ] }),
      meta.error && meta.touched && input.type !== "radio" && /* @__PURE__ */ jsx23("div", { children: /* @__PURE__ */ jsxs16(
        "span",
        {
          className: "h4 text-sm text-brand-error",
          "data-testid": "error-block",
          children: [
            !errorShort && label,
            " ",
            meta.error
          ]
        }
      ) })
    ] });
  } })) });
};
FormControl.displayName = "FormControl";

// src/components/FormControls/validations.tsx
var required = (str) => (value) => value ? void 0 : str ? str : "is required";
var mustBeNumber = (str) => (value) => value && isNaN(value) ? str ? str : "must be a number" : void 0;
var minValue = (min, str) => (value) => value && isNaN(value) || value >= min ? void 0 : `${str ? str : "should be greater than"} ${min}`;
var equalValue = (equalTo) => (value) => value && value.length !== equalTo ? `should be ${equalTo} ${isNaN(value) ? "characters" : "digits"} long.` : void 0;
var minLength = (equalTo) => (value) => value && value.length < equalTo ? `can only be ${equalTo} ${isNaN(value) ? "characters" : "digits"} long.` : void 0;
var minPasswordLength = (equalTo) => (value) => value && value.length < equalTo ? `At least ${equalTo} characters required.` : void 0;
var atleastOneUppercaseChar = (value) => (value == null ? void 0 : value.toString().match(/[A-Z]/)) ? void 0 : "At least one uppercase character required.";
var atleastOneLowercaseChar = (value) => (value == null ? void 0 : value.toString().match(/[a-z]/)) ? void 0 : "At least one lowercase character required.";
var atleastOneNumber = (value) => (value == null ? void 0 : value.toString().match(/[0-9]/)) ? void 0 : "At least one number required.";
var atleastOneSpecialChar = (value) => (value == null ? void 0 : value.toString().match(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/)) ? void 0 : "At least one Special char";
var passwordMatch = (equalTo) => (value) => value && value !== equalTo ? `Passwords do not match.` : void 0;
var maxLength = (equalTo, str, chars, digits, long) => (value) => value && value.length > equalTo ? `${str ? str : "can only be"} ${equalTo} ${isNaN(value) ? chars ? chars : "characters" : digits ? digits : "digits"} ${long ? long : "long"}.` : void 0;
var emojiValidation = (value) => (value == null ? void 0 : value.toString().match(new RegExp("\\p{Extended_Pictographic}", "gu"))) ? "is invalid" : void 0;
var onlyCharValidation = (str) => (value) => (value == null ? void 0 : value.toString().match(/^[^(%;/*\\|[\]\-<>@+)]*$/)) ? void 0 : str ? str : "can't contain number and special chars";
var alphaNumericFieldsValidation = (str) => (value) => (value == null ? void 0 : value.toString().match(/^[^(%;/*\\|[\]\-<>@+)]*$/)) ? void 0 : str;
var charValidationWithEmptyString = (str) => (value) => {
  if ((value == null ? void 0 : value.toString()) === "" || (value == null ? void 0 : value.toString()) === null || (value == null ? void 0 : value.toString()) === void 0) {
    return void 0;
  } else {
    return (value == null ? void 0 : value.toString().match(/^[^(%;/*\\|[\]\-<>@+)]*$/)) ? void 0 : str ? str : "can't contain number and special chars";
  }
};
var emailValidation = (value) => (value == null ? void 0 : value.toString().match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) ? void 0 : "is not vaild";
var dateGreaterThan = (compareTo, msg) => (value) => value && new Date(value) < new Date(compareTo) ? msg ? msg : `  should be greater than ` : void 0;
var composeValidators = (...validators) => (value) => validators.reduce(
  (error, validator) => error || validator(value),
  void 0
);

// src/components/ImageAvatar/ImageAvatar.tsx
import { useEffect as useEffect2, useState as useState5 } from "react";
import { classnames as classnames5 } from "utils";
import { jsx as jsx24 } from "react/jsx-runtime";
var ImageAvatar = (_a) => {
  var _b = _a, {
    src,
    initials = "?",
    defaultContainerProps: _c = {}
  } = _b, _d = _c, {
    className: defaultContainerStyles
  } = _d, defaultContainerProps = __objRest(_d, [
    "className"
  ]), {
    imgProps: _e = {}
  } = _b, _f = _e, { className: imgStyles } = _f, imgProps = __objRest(_f, ["className"]);
  const [isError, setIsError] = useState5(false);
  useEffect2(() => {
    src && setIsError(false);
  }, [src]);
  return isError || !src ? /* @__PURE__ */ jsx24(
    "div",
    __spreadProps(__spreadValues({
      className: classnames5(
        "flex h-12 w-12 items-center justify-center rounded-full bg-brand-color-library-gray-200 md:h-14 md:w-14",
        defaultContainerStyles
      ),
      "data-testid": "defaultImg"
    }, defaultContainerProps), {
      children: /* @__PURE__ */ jsx24("span", { className: "label2 leading-none", children: initials })
    })
  ) : /* @__PURE__ */ jsx24(
    "img",
    __spreadValues({
      className: classnames5(
        "h-20 w-20 max-w-none rounded-full object-cover md:h-24 md:w-24",
        imgStyles
      ),
      src,
      alt: "Your Profile",
      onError: ({ currentTarget }) => {
        currentTarget.onerror = null;
        setIsError(true);
      },
      "data-testid": "profilephoto"
    }, imgProps)
  );
};
ImageAvatar.displayName = "ImageAvatar";

// src/components/Checkbox/Checkbox.tsx
import { jsx as jsx25 } from "react/jsx-runtime";
var Checkbox = ({
  name,
  value = "on",
  label,
  invalid = false,
  checked,
  onChange,
  onBlur,
  onFocus
}) => {
  const inputProps = {
    name,
    value,
    checked,
    onChange,
    onBlur,
    onFocus
  };
  return /* @__PURE__ */ jsx25(
    CheckboxInput_default,
    {
      input: inputProps,
      label,
      invalid
    }
  );
};
export {
  Accordion,
  Add_default as Add,
  AppProtectedLayout,
  ArticleCard,
  ArticleCardSkeleton,
  Button,
  Checkbox,
  Chip,
  DefaultPetProfile_default as DefaultPetProfile,
  FormControl,
  IconAdd,
  IconAddCircleOutline_default as IconAddCircleOutline,
  IconAddOutlineBlue_default as IconAddOutlineBlue,
  IconBigCalendar_default as IconBigCalendar,
  IconButton,
  IconCalendar_default as IconCalendar,
  IconCat,
  IconCatDog_default as IconCatDog,
  IconCheckbox,
  IconCheckboxBase,
  IconCheckboxInvalid,
  IconClose_default as IconClose,
  CloseLg_default as IconCloseLg,
  IconCloseOutlined_default as IconCloseOutlined,
  IconDefaultProfilePic_default as IconDefaultProfilePic,
  IconDismissButton_default as IconDismissButton,
  IconDog,
  IconDownArrow_default as IconDownArrow,
  IconEditPhoto_default as IconEditPhoto,
  IconEditSquareOutine_default as IconEditSquareOutline,
  IconExpandDown_default as IconExpandDown,
  IconFoodFinder_default as IconFoodFinder,
  IconHome,
  IconInfo_default as IconInfo,
  IconLeading_default as IconLeading,
  IconNext_default as IconNext,
  IconPrev_default as IconPrev,
  IconSetting,
  ToastCross_default as IconToastCross,
  ToastSuccess_default as IconToastSuccess,
  IconWarning_default as IconWarning,
  ImageAvatar,
  Loader,
  PersonOutline_default as PersonOutline,
  PetNameDefaultImage,
  ProductCard,
  Sidebar,
  Tab,
  Tabs,
  alphaNumericFieldsValidation,
  atleastOneLowercaseChar,
  atleastOneNumber,
  atleastOneSpecialChar,
  atleastOneUppercaseChar,
  charValidationWithEmptyString,
  composeValidators,
  dateGreaterThan,
  emailValidation,
  emojiValidation,
  equalValue,
  maxLength,
  minLength,
  minPasswordLength,
  minValue,
  mustBeNumber,
  onlyCharValidation,
  passwordMatch,
  required
};

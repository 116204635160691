import { filterIconWhite } from "../../../icons/filterIconWhite";
import { useProducts } from "../../../pages/MyPets/context/ProductsContext";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { ProductCard } from "ui";
import { HillsProductType } from "../../../../types";
import { CloseIconWhite } from "../../../icons/closeIconWhite";
import { filterIcon } from "../../../icons/filterIcon";
import { Accordion } from "./Accordion";
import { Chip } from "./Chip";
import { filterConfigs } from "./FilterConfig/FilterConfig";
import { SearchBar } from "./SearchBar";
import {
  applyFilters,
  extractUniqueArrayValues,
  extractUniqueTagsMapValues,
  extractUniqueTopLevelValues,
  normalizeStringValue,
  sortLifeStages,
} from "./Util/filtersLogic";
import { datalayerPush } from "../../../lib/gtm";
import GTM_ACTIONS from "../../../config/gtmActions";
import { ProductCardListSkeleton } from "./ProductCardListSkeleton";

const FoodSelectionModal = ({
  petType,
  updateSelectedFood,
  resetFiltersTrigger,
}: {
  petType: "dog" | "cat";
  updateSelectedFood: (selectedFoodId: string | undefined) => void;
  resetFiltersTrigger: boolean;
}) => {
  const [selectedFilters, setSelectedFilters] = useState<{
    filterBrand: string[];
    productForm: string[];
    lifestage: string[];
    healthCategory: string[];
    productFamily: string[];
  }>({
    filterBrand: [],
    productForm: [],
    lifestage: [],
    healthCategory: [],
    productFamily: [],
  });

  const [searchQuery, setSearchQuery] = useState<string>("");

  // State to manage expanded state of accordions
  const [expandedAccordions, setExpandedAccordions] = useState<{
    [key: string]: boolean;
  }>({
    filterBrand: false,
    productType: false,
    lifestage: false,
    healthCategory: false,
    additionalNeeds: false,
  });

  const [showMobileFilters, setShowMobileFilters] = useState(false);
  const [executeSearch, setExecuteSearch] = useState(false);
  const [searchSuggestions, setSearchSuggestions] = useState<string[]>([]);

  const { t, i18n } = useTranslation();

  const products = useProducts({ pet: petType });

  if (!products.isSuccess) {
    return null;
  }

  const onSelection = (product: HillsProductType) => {
    datalayerPush({
      ...GTM_ACTIONS.MY_PETS_DIET_FOOD_SELECT_SAVE,
      click_text: `my pets diet food select save: ${product.id}, ${product.name}`,
    });
    updateSelectedFood(product.id);
  };

  // ******** Start Filters and Search *********
  const handleSearchInputChange = (query: string) => {
    setSearchQuery(query);
    setExecuteSearch(false);

    if (query.length > 0) {
      const lowerCaseQuery = query.toLowerCase();
      const filteredSuggestions = products.data
        .filter(
          (product) =>
            product.name && product.name.toLowerCase().includes(lowerCaseQuery)
        )
        .sort((a, b) => {
          const nameA = a.name.toLowerCase();
          const nameB = b.name.toLowerCase();
          const indexA = nameA.indexOf(lowerCaseQuery);
          const indexB = nameB.indexOf(lowerCaseQuery);

          if (nameA === lowerCaseQuery) return -1;
          if (nameB === lowerCaseQuery) return 1;
          if (indexA === 0) return -1;
          if (indexB === 0) return 1;
          if (indexA !== indexB) return indexA - indexB;
          return nameA.length - nameB.length;
        });

      setSearchSuggestions(
        filteredSuggestions.map((product) => product.name).slice(0, 5)
      );
    } else {
      setSearchSuggestions([]);
    }
  };

  // Update the filteredProducts logic to apply filters and search query
  const filteredProducts = useMemo(() => {
    return applyFilters(products.data, selectedFilters, t).filter((product) =>
      executeSearch
        ? normalizeStringValue(product?.name || "")
            .toLowerCase()
            .includes(normalizeStringValue(searchQuery || "").toLowerCase())
        : true
    );
  }, [products.data, selectedFilters, executeSearch, searchQuery, t]);

  const productCount = filteredProducts.length;
  const showingProduct = t(
    productCount === 1
      ? "foodSelect.showingProduct.found"
      : "foodSelect.showingProduct.found_plural",
    { count: productCount }
  );

  const handleFilterSelect = (
    category: keyof typeof selectedFilters,
    filter: string
  ) => {
    setSelectedFilters((prevFilters) => {
      return {
        ...prevFilters,
        [category]: prevFilters[category].includes(filter)
          ? prevFilters[category]
          : [...prevFilters[category], filter],
      };
    });
  };

  // Function to handle filter removal with category and filter
  const handleFilterRemove = (
    category: keyof typeof selectedFilters,
    filter: string
  ) => {
    setSelectedFilters((prevFilters) => {
      return {
        ...prevFilters,
        [category]: prevFilters[category].filter((f) => f !== filter),
      };
    });
  };

  // Flatten the selected filters into a single array for rendering the chips
  const allSelectedFilters = Object.entries(selectedFilters).flatMap(
    ([category, filters]) => filters.map((filter) => ({ category, filter }))
  );

  const getTranslatedLabels = (labels: string[], key: string) => {
    return labels.map((label) =>
      key === "filterBrand" ? t(`lifestage.${label}`) : label
    );
  };

  // Function to clear all filters
  const clearAllFilters = useCallback(() => {
    setSelectedFilters({
      filterBrand: [],
      productForm: [],
      lifestage: [],
      healthCategory: [],
      productFamily: [],
    });
    setExpandedAccordions({
      filterBrand: false,
      productType: false,
      lifestage: false,
      healthCategory: false,
      additionalNeeds: false,
    });
    setSearchQuery("");
  }, []);

  useEffect(() => {
    if (resetFiltersTrigger) {
      clearAllFilters();
    }
  }, [resetFiltersTrigger, clearAllFilters]);

  const handleAccordionToggle = (key: string) => {
    setExpandedAccordions((prevExpanded) => ({
      ...prevExpanded,
      [key]: !prevExpanded[key],
    }));
  };

  return (
    <div>
      <Form
        onSubmit={onSelection}
        render={({ handleSubmit }) => (
          <div className="min-w-full bg-white">
            <form id="foodSelectionForm" onSubmit={handleSubmit} role="form">
              <div className="flex flex-col lg:flex-row">
                {/* Search bar (second row on mobile/tablet) */}
                <div className="shadow-md bg-brand-color-library-blue-800 p-4 lg:hidden">
                  <SearchBar
                    onSearchInputChange={handleSearchInputChange}
                    onSearchExecute={() => setExecuteSearch(true)}
                    searchSuggestions={searchSuggestions}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                  />
                </div>
                <div className="max-h-[calc(100vh-146px)] overflow-y-auto">
                  {/* Filters button and products count (third row on mobile/tablet) */}
                  <div className="flex flex-wrap items-center justify-between px-6 pt-4 pb-1 lg:hidden">
                    {showMobileFilters ? (
                      <>
                        <h2 className="text-lg font-semibold">
                          {t("foodSelect.filtersMobileButton")}
                        </h2>
                        {/* Close Filters button is from the reusable Modal component */}
                      </>
                    ) : (
                      <button
                        className="flex items-center rounded-md border border-gray-800 p-2 font-semibold"
                        onClick={() => setShowMobileFilters(!showMobileFilters)}
                      >
                        <span className="mr-2">{filterIcon}</span>
                        {t("foodSelect.filtersMobileButton")}
                        <span className="ml-2">
                          ({Object.values(selectedFilters).flat().length})
                        </span>
                      </button>
                    )}
                    <div className="font-light">{showingProduct}</div>
                    {productCount === 0 && (
                      <div className="flex flex-col items-center justify-center">
                        <h2 className="text-3xl font-semibold">
                          {t("foodSelect.noProductsFound")}
                        </h2>
                        <p className="mt-4 text-xl">
                          {t("foodSelect.tryClearFilterSearch")}
                        </p>
                      </div>
                    )}
                  </div>
                  {/* Chips and tooltip (displayed beneath the Filters button on mobile/tablet) */}
                  {!showMobileFilters && (
                    <div className="flex flex-wrap items-center gap-1 p-4 pt-1 lg:hidden">
                      {allSelectedFilters.map(({ category, filter }) => {
                        const translationKey = `lifestage.${filter}`;
                        const hasExactTranslation = i18n.exists(translationKey);
                        const translatedFilterLabel = hasExactTranslation
                          ? t(translationKey)
                          : filter;

                        return (
                          <Chip
                            key={`${category}-${filter}`}
                            label={translatedFilterLabel}
                            onClose={() =>
                              handleFilterRemove(
                                category as keyof typeof selectedFilters,
                                filter
                              )
                            }
                          />
                        );
                      })}
                    </div>
                  )}
                  {/* Mobile Filters section (covers the entire viewport on mobile/tablet when showMobileFilters is true) */}
                  {showMobileFilters && (
                    <div className="fixed inset-0 z-50 overflow-y-auto bg-white lg:hidden">
                      <div className="flex items-center justify-between bg-brand-color-library-blue-800 pt-4 pr-4 pb-0 pl-4">
                        <h3 className="flex items-center space-x-2 text-lg font-semibold text-white lg:hidden">
                          {filterIconWhite}
                          <span>{t("foodSelect.filtersMobileButton")}</span>
                        </h3>
                      </div>
                      {/* Close button */}
                      <div className="absolute top-0 right-0 p-4 text-lg">
                        <button
                          type="button"
                          onClick={() => setShowMobileFilters(false)}
                        >
                          <CloseIconWhite />
                        </button>
                      </div>
                      <div className="flex flex-wrap gap-2 bg-brand-color-library-blue-800 p-4 ">
                        {allSelectedFilters.map(({ category, filter }) => {
                          const translationKey = `lifestage.${filter}`;
                          const hasExactTranslation =
                            i18n.exists(translationKey);
                          const translatedFilterLabel = hasExactTranslation
                            ? t(translationKey)
                            : filter;

                          return (
                            <Chip
                              key={`${category}-${filter}`}
                              label={translatedFilterLabel}
                              onClose={() =>
                                handleFilterRemove(
                                  category as keyof typeof selectedFilters,
                                  filter
                                )
                              }
                            />
                          );
                        })}
                      </div>
                      {filterConfigs.map(
                        ({ key, isFirstAccordion, useTopLevelExtraction }) => {
                          if (key === "productFamily") {
                            return null;
                          }
                          const translatedFilterTitle = t(
                            `foodSelect.filters.${key}`
                          );
                          let extractedLabels: string[] = [];

                          if (useTopLevelExtraction && key === "filterBrand") {
                            // Extract the raw labels
                            extractedLabels = extractUniqueTopLevelValues(
                              products.data,
                              "filterBrand"
                            );
                            // Translate the labels
                            extractedLabels = getTranslatedLabels(
                              extractedLabels,
                              key
                            );
                          } else if (key === "healthCategory") {
                            extractedLabels = extractUniqueArrayValues(
                              products.data,
                              key as keyof HillsProductType
                            );
                          } else {
                            extractedLabels = extractUniqueTagsMapValues(
                              products.data,
                              key as keyof HillsProductType["tagsMap"]
                            );
                          }

                          // Apply custom sorting if the key is 'lifestage'
                          if (key === "lifestage") {
                            extractedLabels = sortLifeStages(extractedLabels);
                          }

                          return (
                            <Accordion
                              keyToMap={key}
                              title={translatedFilterTitle}
                              expanded={expandedAccordions[key]}
                              onToggle={() => handleAccordionToggle(key)}
                              labels={extractedLabels}
                              selectedFilters={selectedFilters[key]}
                              onFilterSelect={(filter) =>
                                handleFilterSelect(key, filter)
                              }
                              onFilterRemove={(filter) =>
                                handleFilterRemove(key, filter)
                              }
                              isFirstAccordion={isFirstAccordion}
                            />
                          );
                        }
                      )}

                      {/* Bottom buttons on filters view */}
                      <div className="fixed bottom-0 w-full border-t border-gray-200 bg-white">
                        <div className="flex justify-center space-x-4 p-4 md:justify-end">
                          <button
                            type="button"
                            className="xs:px-1 h-10 overflow-hidden text-ellipsis whitespace-nowrap rounded  border border-brand-color-library-blue-500 px-4 py-2 font-semibold text-brand-color-library-blue-500"
                            onClick={clearAllFilters}
                            title={t("foodSelect.clearAll") || ""}
                          >
                            {t("foodSelect.clearAll")}
                          </button>
                          <button
                            className="w-45 h-10 flex-shrink-0 rounded bg-brand-color-library-blue-500 px-4 py-2 font-semibold text-white"
                            onClick={() => setShowMobileFilters(false)}
                          >
                            {showingProduct}
                          </button>
                        </div>
                      </div>
                      {/* End Bottom buttons on filters view */}
                    </div>
                  )}

                  <div className="px-4 lg:hidden">
                    {products.isPending ? (
                      <ProductCardListSkeleton
                        select={t("foodSelect.select") as string}
                      />
                    ) : (
                      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                        {filteredProducts.map((product: HillsProductType) => (
                          <ProductCard
                            key={product?.id}
                            id={product?.id}
                            primaryProductImageUrl={
                              product?.primaryProductImageUrl
                            }
                            name={product?.name}
                            productDesc={product?.productDesc}
                            select={t("foodSelect.select") as string}
                            children=""
                            buttonCallback={() => onSelection(product)}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                {/* Left section with filters (1/3 width on desktop) */}
                <div className="hidden lg:block lg:w-1/3 lg:border-gray-200">
                  <div className="flex justify-between py-4">
                    <h3 className="font-semibold">
                      {t("foodSelect.selectedFilters")}
                    </h3>
                    <button
                      type="button"
                      className="font-medium text-blue-500 underline"
                      onClick={clearAllFilters}
                    >
                      {t("foodSelect.clearAll")}
                    </button>
                  </div>
                  <div className="flex flex-wrap gap-2 py-4">
                    {allSelectedFilters.map(({ category, filter }) => {
                      let translatedFilterLabel = filter;
                      if (category !== "brand") {
                        const translationKey = `lifestage.${filter}`;
                        const hasExactTranslation = i18n.exists(translationKey);
                        translatedFilterLabel = hasExactTranslation
                          ? t(translationKey)
                          : filter;
                      }
                      return (
                        <Chip
                          key={`${category}-${filter}`}
                          label={translatedFilterLabel}
                          onClose={() =>
                            handleFilterRemove(
                              category as keyof typeof selectedFilters,
                              filter
                            )
                          }
                        />
                      );
                    })}
                  </div>
                  {filterConfigs.map(
                    ({ key, isFirstAccordion, useTopLevelExtraction }) => {
                      if (key === "productFamily") {
                        return null;
                      }
                      const translatedFilterTitle = t(
                        `foodSelect.filters.${key}`
                      );
                      let extractedLabels: string[] = [];

                      if (useTopLevelExtraction && key === "filterBrand") {
                        // Extract the raw labels
                        extractedLabels = extractUniqueTopLevelValues(
                          products.data,
                          "filterBrand"
                        );
                        // Translate the labels
                        extractedLabels = getTranslatedLabels(
                          extractedLabels,
                          key
                        );
                      } else if (key === "healthCategory") {
                        extractedLabels = extractUniqueArrayValues(
                          products.data,
                          key as keyof HillsProductType
                        );
                      } else {
                        extractedLabels = extractUniqueTagsMapValues(
                          products.data,
                          key as keyof HillsProductType["tagsMap"]
                        );
                      }

                      // Apply custom sorting if the key is 'lifestage'
                      if (key === "lifestage") {
                        extractedLabels = sortLifeStages(extractedLabels);
                      }

                      return (
                        <Accordion
                          keyToMap={key}
                          title={translatedFilterTitle}
                          expanded={expandedAccordions[key]}
                          onToggle={() => handleAccordionToggle(key)}
                          labels={extractedLabels}
                          selectedFilters={selectedFilters[key]}
                          onFilterSelect={(filter) =>
                            handleFilterSelect(key, filter)
                          }
                          onFilterRemove={(filter) =>
                            handleFilterRemove(key, filter)
                          }
                          isFirstAccordion={isFirstAccordion}
                        />
                      );
                    }
                  )}
                </div>

                {/* Right section with product cards (2/3 width on desktop) */}
                <div className="hidden lg:block lg:w-2/3">
                  <div className="p-4 pl-0">
                    <SearchBar
                      onSearchInputChange={handleSearchInputChange}
                      onSearchExecute={() => setExecuteSearch(true)}
                      searchSuggestions={searchSuggestions}
                      searchQuery={searchQuery}
                      setSearchQuery={setSearchQuery}
                    />
                  </div>
                  <div className="max-h-[calc(100vh-204px)] overflow-y-auto">
                    <div className="pl-10 font-light">{showingProduct}</div>
                    <div className="p-4">
                      {productCount === 0 && (
                        <div className="flex flex-col items-center justify-center">
                          <h2 className="text-3xl font-semibold">
                            {t("foodSelect.noProductsFound")}
                          </h2>
                          <p className="mt-4 text-xl">
                            {t("foodSelect.tryClearFilterSearch")}
                          </p>
                        </div>
                      )}
                    </div>
                    <div>
                      {products.isPending ? (
                        <ProductCardListSkeleton
                          select={t("foodSelect.select") as string}
                        />
                      ) : (
                        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                          {filteredProducts.map((product: HillsProductType) => (
                            <ProductCard
                              key={product?.id}
                              id={product?.id}
                              primaryProductImageUrl={
                                product?.primaryProductImageUrl
                              }
                              name={product?.name}
                              classes=""
                              productDesc={product?.productDesc}
                              select={t("foodSelect.select") as string}
                              children=""
                              buttonCallback={() => onSelection(product)}
                            />
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        )}
      />
    </div>
  );
};

export default FoodSelectionModal;
